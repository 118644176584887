import React from "react";
import { useParams } from "react-router-dom";

import img1 from "../../assets/programmes/ExcElle.png";
import img2 from "../../assets/programmes/accelerator.png";
import img3 from "../../assets/programmes/Catapulte.png";
import img4 from "../../assets/programmes/Impulse.png";
import img5 from "../../assets/programmes/Intensif.png";
import img6 from "../../assets/programmes/Propulse.png";
import img7 from "../../assets/programmes/Rayonne.png";
// import img8 from '../../assets/1x/IMG-20241028-WA0010.jpg'
// import img9 from '../../assets/1x/IMG-20241028-WA0007.jpg'
// import img10 from '../../assets/1x/IMG-20241028-WA0008.jpg'
import { ArrowRight02Icon } from "hugeicons-react";

export default function ProgrammesDetail() {
  const { nameprog } = useParams();

  const datas = [
    {
      image: img1,
      title: "excElle",
      mont: 600,
      link: "https://ccca.mykajabi.com/programme-excelle",
      corps: [
        "Programme ExcElle est un programme de perfectionnement de dirigeantes féminines, à partir des femmes, par les femmes et pour les femmes. C’est un programme d’excellence féminine pour nos entrepreneures et cheffe d’entreprise qui mêle à la fois développement personnel,réseaux et entraide"
      ]
    },
    {
      image: img2,
      title: "accelerator",
      mont: 600,
      link: "https://ccca.mykajabi.com/programme-accelerator",
      corps: [
        "Ce programme est exclusivement consacré à <mark>des opérateurs économiques, des commerçants et des entrepreneurs qui sont au Canada ou en Afrique</mark> et qui souhaitent distribuer leurs marchandises, leurs produits ou leurs services dans l'une ou l'autre géographie. Pour ceux qui sont au Canada, c'est un programme qui leur permet de découvrir les différents marchés africains possibles pour écouler leurs marchandises, leurs produits, ou bien même pour proposer ou fournir leurs services. Le programme est structuré en fonction de la demande du membre, en fonction du secteur d'activité ou du secteur commercial.",
        "Le programme peut également <mark>suggérer des marchés en Afrique à un fournisseur canadien</mark>. Il peut <mark>suggérer des marchés en fonction de notre outil d'intelligence commerciale</mark>. Nous pouvons suggérer des marchés qui sont des marchés innovants, qui sont des marchés à en devenir, qui sont des marchés à forte prospérité.",
        "Le programme également peut mettre à la disposition du membre <mark>un rapport ou une fiche commerciale qui permet de présenter la valeur du produit, les difficultés, les risques de ce produit</mark>, s'il devait être écoulé sur le marché d'un pays africain faisant partie de notre la géographie de la CCCA. Ce programme accompagne le membre qui est au Canada sur le marché africain à partir des personnes sur le terrain, c'est-à-dire à partir des contacts clés sur le terrain. Ce n'est donc pas un programme spéculatif ni un programme approximatif. C'est un programme qui est essentiellement empirique. C'est un programme qui repose sur des données scientifiques, sur des données réelles, sur des données actualisées.",
        "L'une des phases est justement une approche du terrain. L'une des phases est justement consacrée à l'expérimentation sur le terrain ou à la collecte d'informations sur le terrain.",
        "Pour les entrepreneurs, bien pour les membres qui sont en Afrique et qui veulent plutôt s'exporter sur le marché canadien, le programme leur apporte de l'information clé sur les chaînons manquants au niveau de l'économie canadienne afin de préparer leurs produits à une éventuelle concurrence ou à limiter ou à prévenir contre certains risques de concurrence.",
        "Le programme permet également aux entreprises ou aux membres qui sont en Afrique et qui souhaitent vendre sur le marché canadien d'avoir l'ensemble des outils dans la trousse d'outils du commerce international au canada. Grâce au programme, des mises en relation avec certains accélérateurs peut se faire pour distribuer rapidement un produit ou bien pour faire connaître le produit sur le marché canadien. Les membres qui souscrivent à ce programme bénéficient d'une expertise dans un réseau très important qui est fourni par nos partenaires et dont les experts sont assez réputés au Canada dans le secteur du commerce international et du négoce.",
        "La structure de ce programme est composée d'un certain nombre d'éléments : <ul class='list-disc ps-4 max-md:ps-4 mt-2 text-start text-orange-600 font-medium'><li class=''>Identification de la Marchandise à distribuer sur le Marché Canadien</li><li>Mise en place d’un plan stratégique de distribution</li><li>Identification des partenaires de distribution</li><li>Accompagnement pour l’obtention des autorisations légales relatives aux normes et règlementation en matière de produit alimentaire importé au Canada</li><li>Formation sur la culture d’affaires importé au Canada</li><li>Recherche et mise à disposition de centre de stockage de marchandise venant de pays de membres africains</li><li>Possibilité de jumelages avec d’autres acteurs du même secteur provenant du même pays d’importation.</li></ul>"
      ]
    },
    {
      image: img3,
      title: "catapulte",
      mont: 600,
      link: "https://ccca.mykajabi.com/programme-catapulte",
      corps: [
        "Le programme CATAPULT repose sur un réseau et un lobby assez fort en Afrique comme au Canada, qui permet à des personnes qui <strong class='bg-green-400 bg-opacity-30'>ont besoin ou qui recherchent des contacts clés, pour pouvoir mettre à un niveau très haut leurs affaires, leurs entreprises, leurs projets et autres</strong>. Ce réseau-là est constitué de personnes et d'institutions qui jouissent d'une forte réputation, à partir de laquelle la Chambre de Commerce Canada Afrique s'appuie et collabore pour pouvoir créer des mises en relation particulières et uniques. Ce programme s'adresse à des membres de la CCCA et fonctionne comme suit :<ul class='list-disc ps-4 max-md:ps-4 mt-2 text-green-700 max-md:text-start flex flex-col gap-2 font-medium'><li>L'entrepreneur, start-uppeur, chef d'entreprise ou toute autre institution d'intérêt qui s'inscrit à ce programme va bénéficier d'une consultation de 30 minutes à une heure avec l'un de vos experts pour pouvoir identifier le besoin spécifique.</li><li>Un plan de lobbying stratégique.</li><li>Une prise de contact avec la personne ou l'organisme d'intérêt.</li><li>L'organisation d'un rendez-vous d'affaires ou d'un dîner d'affaires avec l'organisme ou la personne de contact.</li><li>Suivi-vérification de la correspondance d'intérêts.</li><li>Suivi-résultat ou objectif atteint.</li></ul>"
      ]
    },
    {
      image: img4,
      title: "impulse",
      mont: 600,
      link: "https://ccca.mykajabi.com/programme-impulse",
      corps: [
        "Le programme impulse est composé de missions économique commerciales de prospections pour lesquelles La CCCA va accompagner une ou plusieurs entreprises membres à explorer des opportunités dans 3 pays différents parfois situés dans la même région ou dans des continents différents.",
        "Ces missions sont organisées par la CCCA sous forme de délégation ou de cohorte. Il est nécessaire que les personnes qui s'inscrivent à ce programme comprennent qu'elles feront partir d'un groupe de membres de même secteurs d'activités ou de secteurs différents. Le choix des pays est fait en fonction de priorités économiques et surtout des secteurs économiques qu'on souhaite mettre en avant. Il pourrait avoir des missions économiques axées sur l'exploration de l'immobilier en Afrique et donc une cohorte va être organisée autour de plusieurs entreprises canadiennes souhaitant apprendre mieux des opportunités d'investissement dans le secteur de l'immobilier.",
        "Ce programme peut également s'appuyer sur des évènements organisés dans un pays comme des foires ou des salons auxquels la CCCA va inscrire une délégation et profiter de cet évènement pour faire découvrir des nouvelles opportunités d'affaires dans le pays.",
        "Les missions s'organisent 2 fois par an et visent 3 pays principaux. Les personnes qui souhaitent y participer doivent pouvoir s'inscrire dès l'ouverture du programme.",
        "On a: <ul><li><strong>1er semestre :</strong> Janvier à Juin : Afrique</li><li><strong>2ème Semestre :</strong> Juillet à Décembre : Canada ( Torronto, Montréal)</li></ul>",
        "Les insriptions pour le semestre 1 se font jusqu'à Fevrier au plus tard et celles de Semestre 2 jusqu'à Août au plus tard",
        "Structure du programme: <ul class='list-disc ps-4 text-secondary'><li> Inscription aux évènements, salons et foires</li><li>Préparation des demandes de VISA</li><li>Organisation des maillages virtuels à partir des Webinaires ou sur une plateforme de B2B</li><li>Reservation des hôtels et autres commodités de transport et logistique</li><li>Préparation de envoi d'un guide pratique du participant</li><li>Suivi et autres prestations professionnelles</li></ul>"
      ]
    },
    {
      image: img5,
      title: "intensif",
      mont: 600,
      link: "https://ccca.mykajabi.com/programme-intensif",
      corps: [
        "Le programme intensif se repose sur le programme d'incubation de la CCCA.",
        "Ce programme consiste globalement <mark class='bg-green-700 bg-opacity-30'>à aider une personne voulant se lancer en Affaire de démarrer une entreprise en bonne et due forme sur l'aspect administratif passant par les aspects structurels, organisationnels et gestionnels</mark>.",
        "Le programme est ouvert toute l'année à partir de <strong class='text-green-700'>3.000CAD</strong> Pour une entreprise en démarrage, ayant juste besoin d'outils, vous pouvez prendre des modules à partir de 500CAD",
      ],
      data: [
        {
          title: "PARCOURS 1 : FORMATION À L'ENTREPRENEURIAT",
          sousParcours: [
            {
              name: "Équipe et organisation",
              modules: [
                { name: "Gestion d'équipe et leadership" },
                { name: "Recrutement de son équipe" },
                { name: "Gestion administrative d’une entreprise" }
              ],
              durationLanguage: "1H30 / Français",
              formateurs: ""
            },
            {
              name: "Marketing, Commercialisation et Communication",
              modules: [
                {
                  name: "Définition d'une identité et d'une stratégie de communication"
                },
                { name: "L'art de Pitcher" },
                { name: "Définition d'une stratégie commerciale BtoB et BtoC" },
                { name: "Élaboration d'un business plan" }
              ],
              durationLanguage: "1H30 / Français",
              formateurs: ""
            },
            {
              name: "Gestion et Financement",
              modules: [{ name: "Levée de fonds et Stratégie de Financement" }],
              durationLanguage: "1H30 / Français",
              formateurs: ""
            },
            {
              name: "Juridique et Autres",
              modules: [
                { name: "Statuts, démarches et protection juridique" },
                { name: "Réalité et enjeux de l’entrepreneuriat en Afrique" }
              ],
              durationLanguage: "1H30 / Français",
              formateurs: ""
            }
          ]
        },
        {
          title:
            "PARCOURS SPÉCIFIQUES : DÉVELOPPEMENT DE PROJETS MULTI-ACTEURS",
          sousParcours: [
            {
              name: "Développement de projets multi-acteurs",
              modules: [
                { name: "Introduction à la démarche multi-acteurs" },
                { name: "Partenaires et sa connexion à son écosystème" },
                { name: "Serious game: Le Diapason" },
                { name: "Outils : Le bilan de fonctionnement" }
              ],
              durationLanguage: "2H00 / Français",
              formateurs: ""
            },
            {
              name: "Entrepreneuriat et levée de fonds publics pour la Recherche et Institutionnels",
              modules: [
                { name: "Entrepreneuriat" },
                {
                  name: "Levée de fonds publics (Méthodologie globale et bonnes pratiques pour répondre à un appel d’offres)"
                },
                {
                  name: "Relations entre le projet de recherche, environnement et ses enjeux"
                },
                { name: "Formalisation de l’objet de recherche du projet" },
                {
                  name: "Constituer un consortium pour répondre à un appel d’offres"
                },
                {
                  name: "Travailler son budget et le calendrier de mise en œuvre. Contractualisation et propriété intellectuelle"
                }
              ],
              durationLanguage: "2H00 / Français",
              formateurs: ""
            },
            {
              name: "Développement et financement d’un incubateur",
              modules: [
                { name: "Conception et Gestion d’un Incubateur" },
                { name: "Montage d’un programme d’accompagnement" },
                { name: "L’art du sourcing de projets" },
                { name: "Les outils de base d’un incubateur" },
                { name: "L’art de coacher les entrepreneurs" },
                { name: "Trouver son modèle économique" },
                {
                  name: "Méthodologie globale et bonnes pratiques pour répondre à un appel d’offres"
                },
                {
                  name: "Constituer un consortium pour répondre à un appel d’offres"
                },
                {
                  name: "Travailler son budget et le calendrier de mise en œuvre"
                }
              ],
              durationLanguage: "2H00 / Français",
              formateurs: ""
            }
          ]
        }
      ]
    },
    {
      image: img6,
      title: "propulse",
      mont: 600,
      link: "https://ccca.mykajabi.com/programme-propulse",
      corps: [
        "Ce programme permet spécifiquement de <mark class='bg-blue-600 bg-opacity-30'>renforcer les capacités et les compétences du personnel d’une entreprise afin de garantir une croissance et une certaine stabilité de l’entreprise</mark>. Elle s’adresse à des professionnels qui ont besoin d’être mis à jour sur les outils d’innovation, les nouvelles ou bonnes pratiques.",
        "Ces formations sont certifiantes et dispensées par des experts dans les domaines spécifiques.",
        "Ces programmes peuvent se faire en présentiel ou en distantiel. Il bénéficie d’une collaboration avec plusieurs institutions de formation au Canada :<ul class='ps-4 list-disc text-blue-800'><li>HEC Montréal</li><li>Collège Borreal</li><li>Collège Avalon</li><li>Institut de Gestion en Leadership</li></ul>",
        "Les entreprises peuvent se déplacer en cohorte pour venir au Canada suivre les formations qui sont assorties d’une attestation. De même le programme peut être donné par un expert qui se déplace vers votre entreprise"
      ]
    },
    {
      image: img7,
      title: "rayonne",
      mont: 600,
      link: "https://ccca.mykajabi.com/programme-rayonne",
      corps: [
        "L'inscription permet l'accompagnement complet d'un membre de la CCCA qui souhaite se distinguer de son milieu à partir d'une reconnaissance internationale",
        "Il suit donc une démarche assez rigoureuse qui repose sur plusieurs critères notament <mark>les performances du membres, de son entreprise ou organisation, les capacités professionnelles de leadership de la personne, ses qualités morales et sociales ainsi que ses réalisations</mark>.",
        "Ceux qui souhaitent y participer doivent obligatoirement s'inscrire à partir <strong>de debut d'année</strong>"
      ]
    }
  ];

  const info = datas?.find((x) => x.title === nameprog);
  return (
    <div className="flex flex-col gap-5">
      {/* <h1 className='text-[1.5rem] font-bold text-center'>{nameservice}</h1> */}
      {/* <div className='relative w-full h-full mb-2 max-md:h-[450px] max-sm:h-[300px]'> */}
      <img
        className="w-full h-full mx-auto object-cover"
        src={info?.image}
        alt="#"
      />
      {/* </div> */}
      <a
        href={info?.link}
        target="_blank"
        rel="noreferrer"
        className="flex justify-center w-fit max-md:w-full bg-primary rounded-sm hover:bg-secondary transition-all duration-300 p-2.5 px-5 max-md:px-2 gap-8"
      >
        <div className="flex gap-3 text-white text-[1.05rem] rounded-md overflow-hidden font-semibold">
          <span>Inscrivez vous</span>
          <ArrowRight02Icon />
        </div>
      </a>
      <div className="flex flex-col gap-4">
        {info?.corps.map((x, index) => (
          <p
            key={index}
            className="text-[1.13rem] max-md:text-[.98rem] max-md:text-justify"
            dangerouslySetInnerHTML={{ __html: x }}
          ></p>
        ))}
      </div>

      {info?.data?.length > 0 && 
        <>
        <span className="text-[1.13rem]">La Structure du programme est composée comme suit:</span>
        <div className="">
          <h1 className="text-lg text-center font-bold bg-green-800 text-white p-3">
            RECENSEMENT RÉCAPITULATIF DES FORMATIONS PAR MODULE
          </h1>

          {info?.data.map((parcours, index) => (
            <div className="" key={index}>
              <h2 className="text-sm w-[60%] max-md:w-[85%] text-center mx-auto font-semibold border-x border-black py-2 px-4 max-md:px-0">
                {parcours.title}
              </h2>
              <div className="overflow-x-auto">
                <table className="w-full max-md:w-[600px] border border-black">
                    {index===0 &&
                    <thead className="">
                        <tr>
                        <th className="border w-[30%] border-black p-2">
                            SOUS-PARCOURS
                        </th>
                        <th className="border w-[55%] border-black p-2">MODULES</th>
                        <th className="border w-[15%] border-black p-2">
                            DURÉE ET LANGUE
                        </th>
                        {/* <th className="border border-black p-2">FORMATEURS</th> */}
                        </tr>
                    </thead>
                    }
                  <tbody>
                    {parcours.sousParcours.map((sousParcours, idx) => (
                      <React.Fragment key={idx}>
                        <tr>
                          <td
                            className="border w-[30%] bg-green-800 text-white text-center border-black p-2"
                            rowSpan={sousParcours.modules.length}
                          >
                            {sousParcours.name}
                          </td>
                          <td className="border w-[55%] border-black py-2 px-3">
                            {sousParcours.modules[0].name}
                          </td>
                          <td
                            className="border w-[15%] border-black p-2"
                            rowSpan={sousParcours.modules.length}
                          >
                            {sousParcours.durationLanguage}
                          </td>
                          {/* <td
                            className="border border-black p-2"
                            rowSpan={sousParcours.modules.length}
                          >
                            {sousParcours.formateurs || ""}
                          </td> */}
                        </tr>
                        {sousParcours.modules.slice(1).map((module, modIdx) => (
                          <tr key={modIdx}>
                            <td className="border border-black p-2">
                              {module.name}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
        </>
      }
    </div>
  );
}
