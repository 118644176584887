import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Banner from '../../components/Banner'
import Annimated from '../../components/Annimated'
import SideResponsive from '../../components/SideResponsive'
import { Download02Icon } from 'hugeicons-react'
import pygmalion from '../../assets/programmes/programmes_pygmalion.pdf'
// import KajabiForm from '../../components/kajabiform'


export default function LayoutsProgrammes() {
    const { nameprog } = useParams()
    const data = [
        'excElle',
        'accelerator',
        'catapulte',
        'impulse',
        'intensif',
        'propulse',
        'rayonne',
    ]

    return (
        <Annimated>
            <Banner title={`Programme ${nameprog}`} />
            <div className='px-[5%] py-14 max-md:py-12 gap-10 max-md:gap-6 flex max-md:flex-col w-full'>
                <div className='w-[30%] max-md:w-full flex flex-col gap-3 h-fit'>
                    <a href={pygmalion} target='_blank' rel='noreferrer' className='flex py-3 text-[.9rem] px-4 text-white rounded-sm bg-secondary justify-center items-center gap-4'>Télécharger le document complet<Download02Icon /></a>
                    <div className='border-y-[1px] max-md:border-[1px] border-primary '>
                        <SideResponsive data={data} nameservice={nameprog} page='nos-programmes-pygmalion' />
                        <ul className='flex flex-col  max-md:hidden'>
                            {data?.map((x,inde) =>
                                <li key={inde} className="border-b-[1px] hover:bg-gray-200 border-gray-300 last-of-type:border-none"><NavLink className='px-5 text-opacity-60 service-side py-4 text-[.95rem] capitalize font-medium block' to={`/a/nos-programmes-pygmalion/${x}`}>{x}</NavLink></li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className='w-[70%] max-md:w-full'><Outlet />
                            {/* <KajabiForm /> */}
                </div>
            </div>
        </Annimated>
    )
}
