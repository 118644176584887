import React from 'react'
import formad from '../../assets/1x/formadd.jpeg'
import { ArrowRight03Icon } from 'hugeicons-react'
import { useTranslation } from 'react-i18next'

export default function TemplateFormations() {
    const{t}=useTranslation()

  
    return (
        <div className='mt-2 border-t-4 border-dashed border-secondary pt-4'>
            <h2 className='font-bold text-primary text-[1.5rem] max-md:text-[1.2rem]'>{t('export_expertise')}</h2>
            <p className='mt-4 text-[1.1rem] max-md:text-[1rem] text-justify'>
            <img src={formad} className='w-[44%] max-md:w-full float-left' alt='#' />
                <span dangerouslySetInnerHTML={{__html:t('serv.desc_form.templ1')}}></span>
                <ul>
                    <li className='text-start'><ArrowRight03Icon className='float-left me-3 text-secondary'/> <strong>{t('serv.desc_form.templ1_txt')} :</strong> {t('serv.desc_form.templ1_desc')}</li>
                    <li className='text-start'><ArrowRight03Icon className='float-left me-3 text-secondary'/> <strong>{t('serv.desc_form.templ1_txt2')} :</strong> {t('serv.desc_form.templ1_desc2')}</li>
                </ul><br></br>
                <span dangerouslySetInnerHTML={{__html:t('serv.desc_form.templ2')}}></span>
                <span dangerouslySetInnerHTML={{__html:t('serv.desc_form.templ3')}}></span>
            </p>
        </div>
    )
}
