import React from 'react'
import Annimated from '../../components/Annimated'
import { useTranslation } from 'react-i18next'

export default function DirectionGenerale() {
  const {t}=useTranslation()

  return (
    <Annimated>
      {t('about.direction_exec')}
    </Annimated>
  )
}
