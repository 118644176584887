import React, { Suspense, useEffect, useState } from "react";
import Navsbar from "../components/Navbar/Navsbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import $ from "jquery";
import { ArrowUp02Icon } from "hugeicons-react";
import Offline from "./Error/Offline";
import can from "../assets/cana.jpg"
// import { motion } from 'framer-motion'

const Loader = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => {
        if (prev.length === 3) {
          return "";
        }
        return prev + ".";
      });
    }, 500); // Ajoute un point toutes les 500 ms

    return () => clearInterval(interval);
  }, []);


  return (
    <div
      className="flex flex-col gap-3 items-center justify-center h-screen"
    >
      <div className="relative">
        <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-primary border-opacity-40"></div>
        <img src={can} alt="#" className="absolute w-[50%] object-cover -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2" />
        <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-secondary animate-spin"></div>
      </div>
      <div
        className="text-lg max-md:text-sm font-semibold font-mono text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary"
        style={{ animationDuration: "3s", animationIterationCount: "infinite" }}
      >
        Chambre de Commerce Canada Afrique {dots}
      </div>
    </div>
  );
};

const AppWithLoader = ({ isLoading, children }) => {
  return isLoading ? <Loader /> : <>{children}</>;
};

export default function Layouts() {
  const handleScrolle = () => {
    const scrol = $(window).scrollTop();
    if (scrol <= 850) {
      $(".button-scrolltop").addClass("hidden").removeClass("flex");
    } else {
      $(".button-scrolltop").removeClass("hidden").addClass("flex");
    }
  };
  window.addEventListener("scroll", handleScrolle);
  $(document).ready(() => {
    $(".button-scrolltop").on("click", () => {
      $(document).scrollTop(0);
    });
  });

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  // const userLanguage = navigator.language || navigator.userLanguage;
  // console.log(userLanguage);
  
  // fetch('https://ipapi.co/json/') // Utilisez une API comme ipapi.co pour obtenir les infos de l'utilisateur
  // .then(response => response.json())
  // .then(data => {
  //   console.log(data)
  //   const country = data.country_code; // Récupère le code pays (ex : "NG" pour le Nigeria)
  //   console.log(country)
  //   const language = getLanguageFromCountry(country);
  //   console.log(`Langue probable pour ${country}: ${language}`);
  // })
  // .catch(err => console.error('Erreur:', err));

// Fonction pour déterminer la langue en Afrique
function getLanguageFromCountry(countryCode) {
  // Liste des pays francophones d'Afrique
  const francophoneCountries = [
    "CM", "CI", "SN", "MG", "TG", "BF", "NE", "GA", "BI", "CF", "TD", "CG",
    "GN", "KM", "ML", "DJ", "RW"
  ];

  // Liste des pays anglophones d'Afrique
  const anglophoneCountries = [
    "NG", "GH", "KE", "UG", "TZ", "ZA", "ZM", "ZW", "BW", "MW", "NA", "SL",
    "GM", "LR", "SZ", "SS", "ET"
  ];

  // Attribue le français pour les pays francophones
  if (francophoneCountries.includes(countryCode)) {
    return "fr";
  }

  // Attribue l'anglais pour les pays anglophones
  if (anglophoneCountries.includes(countryCode)) {
    return "en";
  }

  // Par défaut, attribuer l'anglais pour les autres pays (ou ajouter des exceptions)
  return "en";
}

  // console.log(navigator.onLine)
  const [isLoading, setIsLoading] = useState(true);
  // const [opacity, setOpacity] = useState(1);

  const hasLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const timeout = setTimeout(hasLoading, 2500); // Simulate loading time
    return () => clearTimeout(timeout);
  }, []);

  return (
    <AppWithLoader isLoading={isLoading}>
      <Suspense fallback={<div>Loading...</div>}>
      <div className="relative">
        <Navsbar />
        {/* {isOnline ? <Outlet /> : <Offline />} */}
        <Outlet />
        <Footer />
        <div className="fixed button-scrolltop hidden backdrop-blur-md items-center cursor-pointer justify-center bottom-10 max-md:bottom-5 max-md:right-5 right-10 bg-secondary z-10 bg-opacity-40 hover:bg-opacity-100 text-white w-[50px] transition-none duration-500 rounded-md  h-[45px]">
          <ArrowUp02Icon />
        </div>
      </div>
      </Suspense>
    </AppWithLoader>
  );
}
