import React, { useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import entrep from "../../assets/videos/entrepreunariat.jpg";
import repreun from "../../assets/videos/repreunariat.jpg";
import coop from "../../assets/videos/cooperation.jpg";
import francop from "../../assets/videos/francophonie.jpg";
import maillage from "../../assets/videos/maillageAffaire.jpg";
import incubat from "../../assets/videos/incubation.jpg";
import formac from "../../assets/videos/jummelage.jpg";
import Annimated from "../../components/Annimated";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle
} from "@headlessui/react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import Incubations from "../Incubations";

import boreal from "../../assets/videos/boreal.svg";
import agoraliance from "../../assets/videos/Logo AGORAlliance.webp";
import digihub from "../../assets/videos/digihub2.png";
import entreprendreici from "../../assets/videos/Entreprendre_ici_Logo_seul_185.png";
import institutleader from "../../assets/videos/INSTITUT_LEADERSHIP-logo.jpg";
import mentorat from "../../assets/1x/mentorat.PNG";
import TemplateFormations from "../../components/Formations/TemplateFormations";

import Formation from '../Formation'
import { useTranslation } from "react-i18next";

export default function ServiceDetails() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [opens, setOpens] = useState(false);
  const [progs, setProgs] = useState("");
  const [ecole, setEcole] = useState("");
  const [forma, setForma] = useState("");

  const nom = useRef("");
  const mail = useRef("");
  const tel = useRef("");
  const message = useRef("");
  const stat = useRef("");
  const prog = useRef("");

  const progress = useRef("");
  const nameE = useRef("");
  const {t}=useTranslation();

  const { nameservice } = useParams();
  const datas = [
    {
      title: t('serv.entrepreneur'),
      image: entrep,
      para: [
        {
          p: t('serv.desc_entre.txt1')
        },
        {
          p: t('serv.desc_entre.txt2')
        },
        {
          p:t('serv.desc_entre.txt3')
        }
      ],
      liste: [
        {
          libele: t('partenaire'),
          option: [
            boreal,
            institutleader,
            digihub,
            agoraliance,
            entreprendreici
          ]
        }
      ]
    },
    {
      title: t('serv.repreunariat'),
      image: repreun,
      para: [
        {
          p: t('serv.desc_repre.txt1')
        }
      ],
      liste: [
        {
          libele:
          t('serv.desc_repre.liste.libele'),
          option: [
            t('serv.desc_repre.liste.opt1'),
            t('serv.desc_repre.liste.opt2'),
            t('serv.desc_repre.liste.opt3'),
            t('serv.desc_repre.liste.opt4'),
            t('serv.desc_repre.liste.opt5'),
            t('serv.desc_repre.liste.opt6'),
            t('serv.desc_repre.liste.opt7')
          ]
        }
      ],
      prog: []
    },
    {
      title: t('serv.francophonie'),
      image: francop,
      para: [
        {
          p: t('serv.desc_franc.txt1')
        },
        {
          p: t('serv.desc_franc.txt2')
        }
      ],
      liste: [],
      prog: []
    },
    {
      title: t('serv.cooperation'),
      image: coop,
      para: [
        {
          p: t('serv.desc_coope.text1')
        }
      ],
      liste: [],
      prog: []
    },
    {
      title:  t('serv.maillage'),
      image: maillage,
      para: [
        {
          p: t('serv.desc_maillage.txt1')
        },
        {
          p: t('serv.desc_maillage.txt2')
        }
      ],
      liste: [],
      prog: []
    },
    {
      title: t('serv.mentorat'),
      image: maillage,
      para: [
        {
          p: t('serv.desc_mentorat.txt1')
        },
        {
          p: t('serv.desc_mentorat.txt2')
        }
      ],
      liste: [],
      prog: [
        {
          titre: t('serv.desc_mentorat.prog1.titr'),
          descrip:
          t('serv.desc_mentorat.prog1.descr'),
          detail: [
            {
              libelle: t('comprend'),
              value: [
                t('serv.desc_mentorat.prog1.det1')
              ]
            },
            {
              libelle: t('tarif'),
              value: [t('serv.desc_mentorat.prog1.tarif')]
            }
          ],
          link: "#"
        },
        {
          titre: t('serv.desc_mentorat.prog2.titr'),
          descrip:
          t('serv.desc_mentorat.prog2.descr'),
          detail: [
            {
              libelle: t('comprend'),
              value: [
                t('serv.desc_mentorat.prog2.det1'),
                t('serv.desc_mentorat.prog2.det2')
              ]
            },
            {
              libelle: t('tarif'),
              value: [
                t('serv.desc_mentorat.prog2.tarif')
              ]
            }
          ],
          link: "#"
        },
        {
          titre: t('serv.desc_mentorat.prog3.titr'),
          descrip:t('serv.desc_mentorat.prog3.titr'),
          detail: [
            {
              libelle: t('comprend'),
              value: [
                t('serv.desc_mentorat.prog3.det1'),
                t('serv.desc_mentorat.prog3.det2')
              ]
            },
            {
              libelle: t('tarif'),
              value: [
                t('serv.desc_mentorat.prog3.tarif')
              ]
            }
          ],
          link: "#"
        },
        {
          titre: t('serv.desc_mentorat.prog4.titr'),
          descrip: "",
          detail: [
            {
              libelle: t('serv.desc_mentorat.prog4.libele'),
              value: [t('serv.desc_mentorat.prog4.det1')]
            }
          ],
          link: ""
        },
        {
          titre: t('serv.desc_mentorat.prog5.titr'),
          descrip:
          t('serv.desc_mentorat.prog5.descr'),
          detail: [],
          link: ""
        }
      ]
    },
    {
      title: t('serv.incubations'),
      image: incubat,
      para: [
        {
          p: t('serv.desc_incub.txt1')
        }
      ],
      liste: [],
      prog: []
    },
    {
      title: t('serv.formations'),
      image: formac,
      para: [
        {
          p: t('serv.desc_form.txt1')
        }
      ],
      liste: [],
      templ: [
        {
          title: t('serv.desc_form.temp1.titre'),
          descrip:
          t('serv.desc_form.temp1.descr'),
          formm: [
            {
              libele: t('serv.desc_form.temp1.fom1'),
              link: "#"
            },
            {
              libele: t('serv.desc_form.temp1.fom2'),
              link: "#"
            },
            {
              libele: t('serv.desc_form.temp1.fom3'),
              link: "#"
            }
          ]
        },
        {
          title: t('serv.desc_form.temp2.titre'),
          descrip:
          t('serv.desc_form.temp2.descr'),
          formm: [
            {
              libele:
              t('serv.desc_form.temp2.fom1'),
              link: ""
            },
            {
              libele: t('serv.desc_form.temp2.fom2'),
              link: ""
            },
            {
              libele: t('serv.desc_form.temp2.fom3'),
              link: ""
            },
            {
              libele: t('serv.desc_form.temp2.fom4'),
              link: ""
            },
            {
              libele:
              t('serv.desc_form.temp2.fom5'),
              link: ""
            },
            {
              libele: t('serv.desc_form.temp2.fom6'),
              link: ""
            },
            {
              libele: t('serv.desc_form.temp2.fom7'),
              link: ""
            },
            {
              libele: t('serv.desc_form.temp2.fom8'),
              link: ""
            },
            {
              libele: t('serv.desc_form.temp2.fom9'),
              link: ""
            },
            {
              libele: t('serv.desc_form.temp2.fom10'),
              link: ""
            }
          ]
        }
      ],
      prog: []
    }
  ];

  const programme = [
    {
      id: "1",
      titre:
        "Programme de Perfectionnement des Capacités des Dirigeants et Entrepreneurs (PPCDE)",
      descrip:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
    },
    {
      id: "2",
      titre: "Programme de formation sur le Repreneuriat",
      descrip: "Test"
    }
  ];

  const ecoles = [
    {
      id: "1",
      titre: "HEC Montreal"
    },
    {
      id: "2",
      titre: "Universite Laval"
    },
    {
      id: "3",
      titre: "ENAPS"
    },
    {
      id: "4",
      titre: "Universite BOREAL"
    }
  ];

  const sous_form = [
    {
      ecol: "HEC Montreal",
      detail: [
        {
          titre: "Intelligence numérique(Big Data et IA)"
        },
        {
          titre: "Communication, Marketing et vente"
        },
        {
          titre: "Developpement Durable et Ethique"
        },
        {
          titre: "Finances et Comptabilités"
        },
        {
          titre: "Leadership et ressources humaines"
        },
        {
          titre: "Stratégie, Innovation et prise de décision"
        }
      ]
    },
    // {universite laval}
    {
      ecol: "Universite Laval",
      detail: [
        {
          titre: "Certificat Universitaire en Gouvernance des sociétés"
        },
        {
          titre: "Formation Continue en gouvenance"
        },
        {
          titre: "Formation En organisation"
        }
      ]
    },
    // {Boreal}
    {
      ecol: "Universite BOREAL",
      detail: [
        {
          titre: "Entrepreneuriat"
        },
        {
          titre:
            "Commerce International (Import-Exprt Canada-Afrique/ Afrique/Canada)"
        },
        {
          titre: "Expertise minière"
        }
      ]
    }
  ];

  const sendEmail = (templateParams, template) => {
    let res;
    emailjs
      .send("service_lsm0da1", template, templateParams, "GvD_PjWrMcfFFe5bp")
      .then(
        (result) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Requête effectué avec success",
            showConfirmButton: false,
            timer: 1700,
            customClass: {
              title: "text-[1rem] text-primary"
            }
          });
          setOpen(false);
          setOpens(false);
        },
        (error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Requête échoué",
            showConfirmButton: false,
            timer: 1700,
            customClass: {
              title: "text-[1rem]"
            }
          });
        }
      );
    return res;
  };

  const choisirCanada = async () => {
    setLoading(true);
    const templateParams = {
      prog: programme?.find((x) => x.id === progs)?.titre,
      name: nom.current.value,
      ecole: ecole,
      progress: progress.current.value,
      forma: forma,
      nameE: nameE.current.value,
      mail: mail.current.value,
      message: message.current.value,
      stat: stat.current.value,
      to: "info@cc-ca.ca"
    };
    await sendEmail(templateParams, "template_lw9hzmf");
  };

  const info = datas?.filter((z) => z.title === nameservice);
  return (
    <Annimated>
      <Dialog
        Dialog
        open={opens}
        onClose={setOpens}
        className="relative z-[100]"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 w-screen overflow-y-auto z-100">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              {loading && (
                <div
                  role="status"
                  className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                >
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="w-full mt-3 text-center sm:mt-0">
                    <DialogTitle
                      as="h1"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Souscription au service {nameservice}
                    </DialogTitle>
                    <div className="flex flex-col gap-4 mt-2">
                      {/* <div className='flex items-center gap-2'>Formation:
                                                <span className='text-secondary font-semibold text-center leading-5 text-[.85rem]'>{choix}</span></div> */}

                      <div className="flex flex-col w-full gap-3">
                        <input
                          className="formulaire"
                          ref={nom}
                          type="text"
                          placeholder="Nom et Prenom"
                        />
                        <input
                          className="formulaire"
                          ref={mail}
                          type="email"
                          placeholder="Adresse Mail"
                        />
                        <input
                          className="formulaire"
                          ref={progress}
                          type="number"
                          placeholder="Valeur comprise entre 0 et 100"
                        />
                        <select className="formulaire" ref={stat}>
                          <option>Selectionner votre status</option>
                          <option value={"Particulier"}>Particulier</option>
                          <option value={"Entreprise"}>Entreprise</option>
                        </select>
                        <input
                          className="formulaire"
                          ref={nameE}
                          type="text"
                          placeholder="Nom de Votre Entreprise"
                        />
                        <select
                          className="formulaire"
                          onChange={(e) => setProgs(e.target.value)}
                        >
                          <option>Selectionner un programme</option>
                          {programme?.map((x, index) => (
                            <option key={index} value={x.id}>
                              {x.titre}
                            </option>
                          ))}
                        </select>
                        {progs === "1" && (
                          <>
                            <select
                              className="formulaire"
                              value={ecole}
                              onChange={(e) => setEcole(e.target.value)}
                            >
                              <option>Selectionner une école</option>
                              {ecoles?.map((x, index) => (
                                <option key={index} value={x.titre}>
                                  {x.titre}
                                </option>
                              ))}
                            </select>
                            <select
                              className="formulaire"
                              value={forma}
                              onChange={(e) => setForma(e.target.value)}
                            >
                              <option>Selectionner une formation</option>
                              {sous_form
                                ?.find((y) => y.ecol === ecole)
                                ?.detail?.map((x, indexs) => (
                                  <option key={indexs} value={x.titre}>
                                    {x.titre}
                                  </option>
                                ))}
                            </select>
                          </>
                        )}

                        <textarea
                          className="formulaire"
                          ref={message}
                          rows={4}
                          placeholder="Description"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={() => choisirCanada()}
                  className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-green-600 rounded-md shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Soumettre
                </button>
                <button
                  type="button"
                  data-autofocus
                  onClick={() => setOpens(false)}
                  className="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Annuler
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      {/* <h1 className='text-[1.5rem] font-bold text-center'>{nameservice}</h1> */}

      <div className="flex flex-col gap-4">
        <div className="relative w-full h-[300px]">
          <img
            src={info[0]?.image}
            width={100}
            height={100}
            loading="lazy"
            alt="#"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="flex flex-col gap-4">
          {info[0]?.para?.map((y, index) => (
            <p key={index} className="text-[1.1rem] max-md:text-[1rem]">
              {y.p}
            </p>
          ))}
        </div>
        {nameservice !== t('serv.formations') && nameservice !== t('serv.incubations') && (
          <div>
            {info[0]?.liste?.map((y, index) => (
              <div className="mt-3" key={index}>
                <h6 className="text-[1.1rem] max-md:text-[1rem]">{y.libele}</h6>
                <ul
                  className={`${
                    nameservice === t('serv.entrepreneur')
                      ? "grid grid-cols-6 max-md:grid-cols-2 w-[100%]"
                      : "list-disc  ps-10"
                  } gap-6 max-md:ps-4 mt-2`}
                >
                  {y.option?.map((z, ind) => (
                    <>
                      {nameservice ===t('serv.entrepreneur') ? (
                        <li className="block" key={ind}>
                          <img className="h-[70px] w-full" src={z} alt="#" />
                        </li>
                      ) : (
                        <li key={ind}>{z}</li>
                      )}
                    </>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
        {info[0]?.prog?.length > 0 && (
          <div className="flex flex-col gap-10">
            {info[0]?.prog?.map((item, indo) => (
              <div key={indo} className="flex flex-col gap-3">
                <h5 className="text-secondary text-[1.5rem] m-0">
                  {item.titre}
                </h5>
                <p className="text-[1.1rem] text-justify">{item.descrip}</p>
                {item?.detail?.map((a, ak) => (
                  <div key={ak}>
                    <span className="text-[1.4rem] text-primary font-semibold">
                      {a?.libelle}
                    </span>
                    <ul className="list-disc ps-5 max-md:ps-4">
                      {a?.value?.map((t) => (
                        <li className="text-[1.1rem]">{t}</li>
                      ))}
                    </ul>
                  </div>
                ))}
                {item?.link !== "" && (
                  <NavLink
                    to={item.link}
                    className={
                      "text-white mx-auto w-fit p-3 font-semibold px-8 text-[.9rem] rounded-md bg-secondary"
                    }
                  >
                    {t('minscrire')}
                  </NavLink>
                )}
              </div>
            ))}
            <div>
              <span className="text-secondary font-semibold text-[1.5rem] mb-4">
                {t('partenaire_present')}
              </span>
              <img
                src={mentorat}
                alt="#"
                className="w-[210px] mt-3 h-[100px]"
              />
            </div>
          </div>
        )}
        {nameservice === t('serv.incubations') && <Incubations />}
        {nameservice === t('serv.formations') && (
            <div className="flex flex-col gap-12">
              <Formation />
              <TemplateFormations />
            </div>
        )}
        {nameservice !== t('serv.formations') &&
          nameservice !== t('serv.incubations') &&
          nameservice !== t('serv.mentorat') && (
            <button
              onClick={() => setOpens(true)}
              className="px-6 py-3 mt-4 font-semibold text-white rounded-sm bg-secondary"
            >
              {t('stats.souscrire')}
            </button>
          )}
      </div>
    </Annimated>
  );
}
