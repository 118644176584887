import React, { useEffect, useState } from "react";
import TopBar from "./TopBar";
import logo from "../../assets/1x/icone.png";
import logor from "../../assets/1x/icone blanc.png";
import { NavLink } from "react-router-dom";
import {
  ArrowDown01Icon,
  ArrowRight01Icon,
  ArrowRightDoubleIcon,
  Cancel01Icon,
  Menu01Icon
} from "hugeicons-react";
import { Link, useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { FlagIcon, GB, FR, DE, ES, DK, NL } from "react-flag-kit";
import $ from "jquery";
import { useTranslation } from 'react-i18next';

export default function Navsbar() {

  const navigate = useNavigate();
  const {t,i18n } = useTranslation();

  const data = [
    {
      titre: t('serv.entrepreneur'),
      descrip:
        t('serv.desc1')
    },
    {
      titre: t('serv.francophonie'),
      descrip:
        t('serv.desc2')
    },
    {
      titre: t('serv.repreunariat'),
      descrip:
        t('serv.desc4')
    },
    {
      titre: t('serv.cooperation'),
      descrip:
      t('serv.desc3')
    },
    {
      titre: t('serv.maillage'),
      descrip:
      t('serv.desc5')
    },
    {
      titre: t('serv.mentorat'),
      descrip:
      t('serv.desc6')
    },
    {
      titre: t('serv.formations'),
      descrip:
      t('serv.desc7')
    },
    {
      titre: t('serv.incubations'),
      descrip:
      t('serv.desc8')
    }
  ];

  const afrique = [
    {
      titre: "Afrique Centrale",
      pays: [
        {
          nom: "Cameroun",
          link: "#"
        },
        {
          nom: "Angola",
          link: "#"
        },
        {
          nom: "Congo",
          link: "#"
        },
        {
          nom: "Tchad",
          link: "#"
        },
        {
          nom: "Gabon",
          link: "#"
        },
        {
          nom: "Sao Tomé-et-Principe",
          link: "#"
        },
        {
          nom: "Guinée équatoriale",
          link: "#"
        },
        {
          nom: "République Centrafricaine",
          link: "#"
        }
      ]
    },
    {
      titre: "Afrique de l'Ouest",
      pays: [
        {
          nom: "Nigeria",
          link: "#"
        },
        {
          nom: "Burkina Faso",
          link: "#"
        },
        {
          nom: "Cap-vert",
          link: "#"
        },
        {
          nom: "Côte d'ivoire",
          link: "#"
        },
        {
          nom: "Gambie",
          link: "#"
        },
        {
          nom: "Ghana",
          link: "#"
        },
        {
          nom: "Guinée",
          link: "#"
        },
        {
          nom: "Guinée-Bissau",
          link: "#"
        },
        {
          nom: "Libéria",
          link: "#"
        },
        {
          nom: "Mali",
          link: "#"
        }
      ]
    },
    {
      titre: "Afrique de L'est",
      pays: [
        {
          nom: "Burundi",
          link: "#"
        },
        {
          nom: "Kenya",
          link: "#"
        },
        {
          nom: "Djibouti",
          link: "#"
        },
        {
          nom: "Malawi",
          link: "#"
        },
        {
          nom: "Maurice",
          link: "#"
        },
        {
          nom: "Mozambique",
          link: "#"
        },
        {
          nom: "Rwanda",
          link: "#"
        },
        {
          nom: "Ouganda",
          link: "#"
        },
        {
          nom: "Zambie",
          link: "#"
        }
      ]
    },
    {
      titre: "Afrique du Nord",
      pays: [
        {
          nom: "Algérie",
          link: "#"
        },
        {
          nom: "Égypte",
          link: "#"
        },
        {
          nom: "Libye",
          link: "#"
        },
        {
          nom: "Maroc",
          link: "#"
        },
        {
          nom: "Mauritanie",
          link: "#"
        },
        {
          nom: "Soudan",
          link: "#"
        },
        {
          nom: "Tunisie",
          link: "#"
        }
      ]
    },
    {
      titre: "Afrique Australe",
      pays: [
        {
          nom: "Botswana",
          link: "#"
        },
        {
          nom: "Lesotho",
          link: "#"
        },
        {
          nom: "Namibie",
          link: "#"
        },
        {
          nom: "Suaziland",
          link: "#"
        },
        {
          nom: "Afrique du Sud",
          link: "#"
        }
      ]
    },
    {
      titre: "Océan Indien",
      pays: [
        {
          nom: "Comore",
          link: "#"
        },
        {
          nom: "Madagascar",
          link: "#"
        }
      ]
    }
  ];

  const canada = [
    {
      titre: "Alberta"
    },
    {
      titre: "Colombie-Britannique"
    },
    {
      titre: "Île-du-Prince-Édouard"
    },
    {
      titre: "Manitoba"
    },
    {
      titre: "Nouvelle-Écosse"
    },
    {
      titre: "Québec"
    },
    {
      titre: "Saskatchewan"
    },
    {
      titre: "Terre-Neuve-et-Labrador"
    }
  ];

  const activite = [
    {
      titre: t('activitt.act1.titre'),
      descrip:t('activitt.act1.descrip')
    },
    {
      titre: t('activitt.act2.titre'),
      descrip:t('activitt.act2.descrip')
    },
    {
      titre: t('activitt.act3.titre'),
      descrip:t('activitt.act3.descrip')
    },
    {
      titre: t('activitt.act4.titre'),
      descrip:t('activitt.act4.descrip')
    },
    {
      titre: t('activitt.act5.titre'),
      descrip:t('activitt.act5.descrip')
    },
    {
      titre: t('activitt.act6.titre'),
      descrip:t('activitt.act6.descrip')
    },
    {
      titre: t('activitt.act7.titre'),
      descrip:t('activitt.act7.descrip')
    },
    {
      titre: t('activitt.act8.titre'),
      descrip:t('activitt.act8.descrip')
    },
    {
      titre: t('activitt.act9.titre'),
      descrip:t('activitt.act9.titre')
    },
    {
      titre: t('activitt.act10.titre'),
      descrip:t('activitt.act10.descrip')
    }
  ];
  // const [res,setRes]=useState(false)
  // const menu = document.querySelectorAll(".navsbar li");

  // const changePage = (x) => {
  //   menu.forEach((item) => item.classList.remove("active"));
  //   $(".navsbar").removeClass("active");
  //   $(".mobiles").removeClass("absolute");
  //   setMobileopen(false);
  //   navigate(`/a/Bureau/Afrique/${x}`);
  // };
  // const changePageC = (x) => {
  //   menu.forEach((item) => item.classList.remove("active"));
  //   setMobileopen(false);
  //   $(".navsbar").removeClass("active");
  //   $(".mobiles").removeClass("absolute");
  //   navigate(`/a/Bureau/Canada/${x}`);
  // };


  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [opena, setOpena] = useState(false);
  const [openba, setOpenba] = useState(false);
  const [openca, setOpenca] = useState(false);
  const [mobileopen, setMobileopen] = useState(false);
  // const [user, setUser] = useState(null);
  // const [userData, setUserData] = useState({ firstName: "", lastName: "" });
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLngsa') || i18n.language); // Langue par défaut: anglais

  // const handleOpen = () => {
  //   setOpena(false);
  //   setOpenba(false);
  //   setOpens(false);
  //   setOpen(!open);
  // };
  const handleOpens = () => {
    setOpen(false);
    setOpena(false);
    setOpenba(false);
    setOpenca(false);
    setOpens(!opens);
  };
  const handleOpena = () => {
    setOpen(false);
    setOpenba(false);
    setOpenca(false);
    setOpens(false);
    setOpena(!opena);
  };
  const handleOpenba = () => {
    setOpen(false);
    setOpena(false);
    setOpenca(false);
    setOpens(false);
    setOpenba(!openba);
  };
  const handleOpenca = () => {
    setOpen(false);
    setOpena(false);
    setOpens(false);
    setOpenba(false);
    setOpenca(!openca);
  };
  const changePageMenu = (x) => {
    setOpen(false);
    setOpena(false);
    setOpens(false);
    setOpenba(false);
    setOpenca(false);
    setMobileopen(false);
    $(".navsbar").removeClass("active");
    $(".mobiles").removeClass("fixed");
    navigate(`/a/${x}`);
  };


  const handleScrolle = () => {
    const scrol = $(window).scrollTop();
    if (scrol >= 160) {
      $('.logo-scroll').removeClass('hidden')
    }else{
      $('.logo-scroll').addClass('hidden')
    }
    if (scrol >= 300) {
      setOpen(false);
      setOpena(false);
      setOpens(false);
      setOpenba(false);
      setOpenca(false);
    }
    // console.log(scrol)
  };
  window.addEventListener("scroll", handleScrolle);

  const handleMenuOpen = () => {
    setMobileopen(true);
    $(".mobiles").addClass("absolute");
    $(".navsbar").addClass("active");
    // $('#root').addClass('overflow-hidden')
  };
  const handleMenuClose = () => {
    setMobileopen(false);
    $(".mobiles").removeClass("absolute");
    $(".navsbar").removeClass("active");
  };

  const toggleLanguageMenu = () => {
    setShowLanguageMenu(!showLanguageMenu);
  };

  // const handleLanguageChange = (lang) => {
  //   setLanguage(lang);
  //   console.log("Langue sélectionnée :", lang);
  //   setShowLanguageMenu(false);
  // };

  // Détermine le drapeau à afficher pour la langue active
  const getFlag = (lang) => {
    switch (lang) {
      case "fr":
        return <FlagIcon code="FR" size={16} />;
      case "de":
        return <FlagIcon code="DE" size={16} />;
      case "es":
        return <FlagIcon code="ES" size={16} />;
      case "dk":
        return <FlagIcon code="DK" size={16} />;
      case "nl":
        return <FlagIcon code="NL" size={16} />;
      default:
        return <FlagIcon code="GB" size={16} />;
    }
  };

  const handleChangeLanguage = async (lng) => {
    // console.log(lng)
    // console.log(i18n.hasResourceBundle(lng, 'translation'))
    if (!i18n.hasResourceBundle(lng, 'translation')) {
      try {
        await i18n.loadLanguages(lng); // Charger la langue dynamiquement
      } catch (error) {
        console.error('Erreur lors du chargement de la langue :', error);
      }
    }
    i18n.changeLanguage(lng); // Changer la langue après le chargement
    setLanguage(lng);
    setShowLanguageMenu(false);
    window.location.href="/a"
  };
 

  const texte=`<strong class='underline underline-offset-2 capitalize'>${t('annonce')}</strong> :  ${t('communique')}`
  return (
    // <div className='headers'>
    <>
    {/* <ma className="bg-slate-300" direction="left">Hello world</ma> */}
      {/* <Marquee items={announcements} speed={5000} pause={500}/> */}
      <div className="relative py-2 px-[5%] max-md:pl-[2%] max-md:pr-[10%] text-[.9rem] max-md:text-[.7rem] bg-primary bg-opacity-80 text-white defile">
        <div className="absolute top-1/2 -translate-y-1/2 right-[1.8%] cursor-pointer"><Cancel01Icon size={18}/></div>
        <marquee direction="left" scrollamount="7">
          <div className="flex items-center">
          <p className="mr-[100%]" dangerouslySetInnerHTML={{__html:texte}}></p>
          </div>
        </marquee>
      </div>
      <TopBar />
      <div className="relative px-[5%] py-8 bg-white flex items-center max-lg:hidden justify-between">
        <div className="">
          <a href={"/a"} className="flex items-center gap-1">
            <img src={logo} width={45} height={45} alt="#" />
            <div className="flex flex-col font-semibold text-[1.13rem] leading-[1.4rem]">
              <span>Chambre de Commerce</span>
              <span>Canada Afrique</span>
            </div>
          </a>
        </div>
        <div className="flex">
          <a
            href={"/a/Devenir-membre"}
            className="px-4 text-[.85rem] font-semibold"
          >
            {t('becomeMember')}
          </a>
          {/* <NavLink className='px-4 text-[.85rem]'>Devenir Membre</NavLink> */}
        </div>
      </div>
      <div className="mobiles !sticky top-0 transition-all duration-500 bg-secondary bar-rouge w-full px-[5%] z-[100] font-medium flex justify-between items-center gap-1">
        <NavLink
          to={"/a"}
          className="items-center gap-1 max-md:py-2 logo-mobile"
        >
          <img src={logor} width={35} height={35} alt="#" />
          <div className="flex flex-col text-white font-semibold text-[.85rem] leading-[1rem]">
            <span>Chambre de commerce</span>
            <span>Canada Afrique</span>
          </div>
        </NavLink>
        <ul className="text-white text-[.9rem] font-medium shadow-sm navsbar transition-all duration-500 overflow-hidden">
          <li className="za logo-scroll transition-all duration-500 hidden max-md:hidden">
          <a
              href={`/a`}
              className="block px-4 py-2 navlink"
            >
              <img src={logor} className="w-[45px] h-[35px]" width={35} height={35} alt="#" />
            </a>
          </li>
          <li className="cursor-pointer za">
            <a
              href={`/a/Apropos-de-nous/${t('about.presentation')}`}
              className="block px-4 py-4 navlink"
            >
              {t('aboutUs')}
            </a>
          </li>
          <li
            className={`cursor-pointer za ${opens ? "active" : ""}`}
            onClick={handleOpens}
          >
            <NavLink className="flex items-center gap-1 px-4 py-4 link navlink">
            {t('services')}
              <ArrowDown01Icon className="icones" size={"1.1rem"} />
            </NavLink>
            <div
              className={`left-[5%] text-black block-menu bg-gray-200 w-[90%] px-20 pt-7 pb-10`}
            >
              <div className="flex items-center font-semibold text-[1.1rem] gap-2 border-b-[3px] border-secondary pb-2">
                <h4>{t('services')}</h4>
                <ArrowRight01Icon className="" size="1rem" />
              </div>
              <div className="grid grid-cols-3 gap-12 mt-4 text-black max-md:grid-cols-1 gap-y-7 max-md:gap-4">
                {data?.map((x, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    <a
                      href={`/a/Services/${x.titre}`}
                      className="flex text-[1.1rem] font-medium capitalize hover:text-secondary items-center gap-1"
                    >
                      <ArrowRightDoubleIcon color="#E30713" />
                      {x.titre}
                    </a>
                    <span className="text-[.85rem] max-md:hidden text-gray-600 font-extralight text-justify h-[60px] overflow-hidden text-ellipsis">
                      {x.descrip}
                    </span>
                    <a
                      href={`/a/Services/${x.titre}`}
                      className="text-secondary max-md:hidden"
                    >
                     {t('readMore')}...
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </li>
          <li className="cursor-pointer za">
            <a href={"/a/Evenements"} className="block px-4 py-4 navlink">
            {t('events')}
            </a>
          </li>
          <li
            className={`cursor-pointer za ${opena ? "active" : ""}`}
            onClick={handleOpena}
          >
            <NavLink className="px-4 z-[100] link flex py-4 gap-1 items-center navlink">
            {t('activities')}
              <ArrowDown01Icon className="icones" size={"1.1rem"} />
            </NavLink>
            <div
              className={`left-[5%] text-black block-menu top-[100%] bg-gray-200 w-[90%] px-20 pt-7 pb-10`}
            >
              <div
                onClick={() => navigate("/a/Activites/List")}
                className="flex items-center font-semibold text-[1.1rem] gap-2 border-b-[3px] border-secondary pb-2"
              >
                <h4>{t('activities')}</h4>
                <ArrowRight01Icon className="" size="1rem" />
              </div>
              <div className="grid grid-cols-3 gap-12 mt-4 text-black max-md:grid-cols-1 gap-y-7 max-md:gap-4 sous-bl">
                {activite?.map((x, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    <a
                      href={`/a/Activites/${x.titre}`}
                      className="text-[1.1rem] font-medium capitalize transition-all duration-300 hover:text-secondary items-start gap-1"
                    >
                      <ArrowRightDoubleIcon
                        color="#E30713"
                        className="float-left"
                      />
                      {x.titre}
                    </a>
                    <span className="text-[.85rem] max-md:hidden text-gray-600 font-extralight text-justify h-[60px] overflow-hidden text-ellipsis">
                      {x.descrip}
                    </span>
                    <a
                      href={`/a/Activites/${x.titre}`}
                      className="text-secondary max-md:hidden"
                    >
                      {t('readMore')}...
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </li>
          <li
            className={`cursor-pointer za ${openba ? "active" : ""}`}
            onClick={handleOpenba}
          >
            <NavLink className="flex items-center gap-1 px-4 py-4 link navlink">
            {t('africaOffices')}
              <ArrowDown01Icon className="icones" size={"1.1rem"} />
            </NavLink>
            <div
              className={`left-[5%] text-black block-menu top-[100%] bg-gray-200 w-[90%] px-20 pt-7 pb-10`}
            >
              <div className="flex items-center font-semibold text-[1.1rem] gap-2 border-b-[3px] border-secondary pb-2">
                <h4>{t('africaOffices')}</h4>
                <ArrowRight01Icon className="" size="1rem" />
              </div>
              <div className="grid grid-cols-3 gap-12 mt-4 text-black max-md:grid-cols-1 gap-y-7 max-md:gap-4">
                {afrique?.map((x, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    <span
                      onClick={() =>
                        changePageMenu(`Bureau/Afrique/${x.titre}`)
                      }
                      className="flex cursor-pointer text-[1.1rem] font-medium capitalize transition-all duration-300 hover:text-secondary items-center gap-1"
                    >
                      <ArrowRightDoubleIcon color="#E30713" />
                      {x.titre}
                    </span>
                    <ul className="text-[.95rem] max-md:hidden !bg-transparent flex flex-col gap-2 font-medium text-justify">
                      {x.pays?.map((y, indexs) => (
                        <li
                          key={indexs}
                          className="bg-transparent cursor-pointer hover:text-secondary"
                        >
                          <a href={`/a/Bureau/Afrique/${x.titre}/${y.nom}`}>
                            {y.nom}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </li>
          <li
            className={`cursor-pointer za ${openca ? "active" : ""}`}
            onClick={handleOpenca}
          >
            <NavLink className="flex items-center gap-1 px-4 py-4 link navlink">
            {t('canadaRepresentation')}
              <ArrowDown01Icon className="icones" size={"1.1rem"} />
            </NavLink>
            <div
              className={`left-[5%] w-[90%] text-black block-menu top-[100%] bg-gray-200 px-20 pt-7 pb-10`}
            >
              <div className="flex items-center font-semibold text-[1.1rem] gap-2 border-b-[3px] border-secondary pb-2">
                <h4>{t('canadaRepresentation')}</h4>
                <ArrowRight01Icon className="" size="1rem" />
              </div>
              <div className="grid grid-cols-3 gap-12 mt-4 text-black max-md:grid-cols-1 gap-y-7 max-md:gap-4">
                {canada?.map((x, index) => (
                  <div key={index} className="flex flex-col gap-2">
                    <a
                      href={`/a/Bureau/Canada/all/${x.titre}`}
                      className="flex text-[1.1rem] font-medium cursor-pointer capitalize transition-all duration-300 hover:text-secondary items-center gap-1"
                    >
                      <ArrowRightDoubleIcon color="#E30713" />
                      {x.titre}
                    </a>
                    {/* <ul className='text-[.95rem] !bg-transparegap-1nt flex flex-col gap-2 text-gray-600 font-extralight text-justify'>
                                {x.pays?.map((y)=>
                                  <li className='bg-transparent'><NavLink to={y.link}>{y.nom}</NavLink></li>
                                )}
                              </ul> */}
                  </div>
                ))}
              </div>
            </div>
          </li>
          <li
            className="cursor-pointer za"
          >
            <a href={`/a/Actualites`} className="block px-5 py-4 navlink">
            {t('news')}
            </a>
          </li>
          <li
            className="cursor-pointer za"
          >
            <a href={`/a/CCCA REVUE`} className="block px-4 py-4 navlink">
              CCCA REVUE
            </a>
          </li>
          <li className="derniers">
            <a
              className="py-4 px-3 text-center font-semibold text-[.95rem] border-b-[1px] border-gray-300"
              href={"/a/Devenir-membre"}
            >
              Dévenir Membre
            </a>
            <div className="flex items-center">
              <a
                href={"/login"}
                className="w-[70%] px-[9%] py-3 border-r-[1px] font-semibold text-[.95rem] border-gray-300"
              >
                Connexion
              </a>
              <button
                onClick={toggleLanguageMenu}
                className="!flex items-center text-[.7rem] w-[30%] px-4 py-3 justify-center gap-2 cursor-pointer"
                title="Changer de langue"
              >
                {getFlag(language)} <FaChevronDown />
              </button>
              {showLanguageMenu && (
                <div className="absolute right-0 top-0 mt-2 z-[1000] bg-white border border-gray-300 rounded shadow-lg w-40">
                  <div
                    onClick={() => handleChangeLanguage("fr")}
                    className="px-4 py-2 text-[.7rem] cursor-pointer hover:bg-gray-100 flex items-center gap-2"
                  >
                    <FlagIcon code="FR" size={16} /> Français
                  </div>
                  <div
                    onClick={() => handleChangeLanguage("en")}
                    className="px-4 py-2 text-[.7rem] cursor-pointer hover:bg-gray-100 flex items-center gap-2"
                  >
                    <FlagIcon code="GB" size={16} /> Anglais
                  </div>
                </div>
              )}
            </div>
          </li>
        </ul>
        <button
          onClick={!mobileopen ? handleMenuOpen : handleMenuClose}
          className="max-md:py-2"
        >
          {!mobileopen ? (
            <Menu01Icon color="white" size={25} />
          ) : (
            <Cancel01Icon color="white" size={25} />
          )}
        </button>
      </div>
    </>
  );
}
