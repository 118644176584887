import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/effect-fade';
import jambo from '../../assets/jambotv.PNG';
// import pad from '../../assets/1x/page.jpg';
import actual from '../../assets/actual1.jpg';
import actual2 from '../../assets/actu2.avif';
import actual3 from '../../assets/actual2.jpg';
import actual4 from '../../assets/programmes/actual.jpeg';
import actual5 from '../../assets/programmes/actual2.jpeg';
import actual6 from '../../assets/programmes/actual3.jpeg';
import vidJambo from '../../assets/videos/Publier.mp4';
import Banner from '../../components/Banner';
import Infolettre from '../../components/Infolettre';
import Annimated from '../../components/Annimated';
import { ArrowRight01Icon, ArrowLeft01Icon } from 'hugeicons-react';
import Publicite from '../../components/Publicite';
// import moment from 'moment';

export default function Actualites() {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;  

    const data = [
        {
            id: 6,
            image: actual4,
            type: 'interne',
            url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene',
            titre: "𝗥𝗲𝗻𝗳𝗼𝗿𝗰𝗲𝗿 𝗹𝗲𝘀 𝗹𝗶𝗲𝗻𝘀 𝗲𝗻𝘁𝗿𝗲 𝗹𝗮 𝗳𝗿𝗮𝗻𝗰𝗼𝗽𝗵𝗼𝗻𝗶𝗲 𝗲𝘁 𝗹𝗲𝘀 𝗮𝗳𝗳𝗮𝗶𝗿𝗲𝘀 𝗲𝗻 𝗥𝗲́𝗽𝘂𝗯𝗹𝗶𝗾𝘂𝗲 𝗗𝗲́𝗺𝗼𝗰𝗿𝗮𝘁𝗶𝗾𝘂𝗲 𝗱𝘂 𝗖𝗼𝗻𝗴𝗼-𝗘𝘀𝘁 🌍🇨🇦🇨🇩",
            descrip: "M. charles mulanda, délégué pays de la Chambre de Commerce Canada Afrique en 𝗥𝗗𝗖-𝗘𝘀𝘁, a eu l'honneur de participer à une rencontre d'affaires enrichissante avec M. Léandre MIEMA, Délégué Général Permanent de la Francophonie et Correspondant National auprès de l'OIF/RDC.",
            date: '2025-01-22',
            videoUrl: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
        },
        {
            id: 7,
            image: actual6,
            type: 'interne',
            url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene',
            titre: "𝐑𝐞𝐧𝐟𝐨𝐫𝐜𝐞𝐦𝐞𝐧𝐭 𝐝𝐞𝐬 𝐫𝐞𝐥𝐚𝐭𝐢𝐨𝐧𝐬 𝐛𝐢𝐥𝐚𝐭é𝐫𝐚𝐥𝐞𝐬 𝐞𝐧𝐭𝐫𝐞 𝐥’𝐀𝐥𝐠é𝐫𝐢𝐞 𝐞𝐭 𝐥𝐞 𝐂𝐚𝐧𝐚𝐝𝐚 🌍",
            descrip: "Deux audiences marquantes ont eu lieu dans le cadre du développement des relations économiques entre l’Algérie et le Canada :1️⃣ 𝗔𝘂𝗱𝗶𝗲𝗻𝗰𝗲 𝗮𝘂 𝗺𝗶𝗻𝗶𝘀𝘁è𝗿𝗲 𝗱𝗲𝘀 𝗔𝗳𝗳𝗮𝗶𝗿𝗲𝘀 é𝘁𝗿𝗮𝗻𝗴è𝗿𝗲𝘀, 👉 𝐌. 𝐌𝐨𝐡𝐚𝐧𝐝 𝐎𝐮𝐫𝐚𝐦𝐝𝐚𝐧𝐞 𝐋𝐀𝐑𝐀𝐁, délégué de l'Algérie auprès de la Chambre de Commerce Canada-Afrique (CCCA), a échangé avec 𝐌. 𝐑𝐚𝐛𝐚𝐡 𝐅𝐚𝐬𝐢𝐡, Directeur de la Coopération et des Échanges Économiques.",
            date: '2025-01-18',
            videoUrl: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
        },
        {
            id: 8,
            image: actual5,
            type: 'interne',
            url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene',
            titre: "Un partenariat prometteur avec l’Agence Guinéenne de Promotion des Exportations (AGUIPEX)",
            descrip:"Ce jeudi 16 janvier 2025 à 18h30, Mr Robin Camara, Représentant Pays de la Chambre de Commerce Canada Afrique et son assistante Mme 𝗗𝗶𝗲𝗻𝗴 𝗦𝗲𝗹𝗹𝘆 𝗦𝗲𝗰𝗸, ont été reçus par M. Amadou D’affaires Baldé, Directeur Général de l' Agence Guinéenne de ...",
            date: '2025-01-18',
            videoUrl: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
        },
        {
            id: 1,
            image: jambo,
            type: 'interne',
            url: '',
            titre: t('news_title1'),
            descrip: t('news_description1'),
            date: '2024-09-21',
            videoUrl: vidJambo
        },
        {
            id: 3,
            image: actual,
            type: 'externe',
            url: 'https://francopresse.ca/actualite/2024/10/07/exode-des-cerveaux-lafrique-se-vide-au-profit-du-canada/',
            titre: t('news_title2'),
            descrip: t('news_description2'),
            date: '2024-09-10',
            videoUrl: 'https://francopresse.ca/actualite/2024/10/07/exode-des-cerveaux-lafrique-se-vide-au-profit-du-canada/'
        },
        {
            id: 4,
            image: actual2,
            type: 'externe',
            url: 'https://ici.radio-canada.ca/nouvelle/2108988/recruter-infirmieres-afrique-ethique-caq-maroc-tunisie',
            titre: t('news_title3'),
            descrip: t('news_description3'),
            date: '2024-09-05',
            videoUrl: 'https://ici.radio-canada.ca/nouvelle/2108988/recruter-infirmieres-afrique-ethique-caq-maroc-tunisie'
        },
        {
            id: 5,
            image: actual3,
            type: 'externe',
            url: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene',
            titre: t('news_title4'),
            descrip: t('news_description4'),
            date: '2024-09-01',
            videoUrl: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
        }
    ];

    
    const filteredData = data.filter((item) => {
        const matchesSearchQuery = item.titre.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesDate = selectedDate ? item.date === selectedDate : true;
        return matchesSearchQuery && matchesDate;
    });

   
    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <Annimated>
            <Banner title={t('news_banner_title')} />
            <div className="px-[5%] w-full flex gap-[3rem] py-14 max-md:py-12">
                <div className='w-[73%] max-md:w-full'>
                    <div className='flex flex-col gap-5 pb-0'>
                        <div className='flex flex-col gap-4 md:flex-row'>
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder={t('search_by_title')}
                                className="border-[1px] border-primary p-2 px-4 w-full md:w-[50%] outline-none rounded-md"
                            />
                            <input
                                type="date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                className="border-[1px] border-primary p-2 px-4 w-full md:w-[50%] outline-none rounded-md"
                            />
                        </div>
                        <span className='text-[.9rem] font-semibold'>{filteredData.length} {t('results')}</span>
                    </div>
                    <div className='flex flex-col'>
                        {paginatedData.map((x,index) => (
                            <a href={x.type==='interne'?`/a/Actualites/${x.titre}`:x.url} key={x.id} target={x.type==='externe'?'_blank':''} rel='noreferrer' className='relative w-full flex max-md:flex-col py-5 flex-row-reverse gap-3 overflow-hidden transition-all duration-500 border-b-[1px] border-primary last-of-type:border-none cursor-pointer'>
                                <div className='w-[35%] max-md:h-[300px] relative max-md:w-full'>
                                    <img className='absolute object-cover w-full h-full rounded-md' src={x.image} alt={x.titre} />
                                </div>
                                <div className='flex flex-col max-md:items-center max-md:p-1 gap-3 max-md:gap-3 w-[65%] max-md:w-full'>
                                    <h1 className='text-[1.6rem] hover:text-secondary transition-all duration-300 max-md:text-[1.1rem] max-md:text-center font-semibold text-primary' dangerouslySetInnerHTML={{__html:x.titre}}></h1>
                                    <span className='text-[1.1rem] max-md:text-center max-md:text-[.92rem] font-normal' dangerouslySetInnerHTML={{__html:x.descrip}}></span>
                                    <span className='text-[.75rem] text-gray-500 font-semibold'>{t('published_on')} : <span className='text-green-600'>{new Date(x.date).toLocaleDateString()}</span></span>
                                </div>
                            </a>
                        ))}
                    </div>
                    <div className='flex items-center justify-center w-full pt-10'>
                        <ul className='flex gap-1 p-2'>
                            <li
                                className={`p-1 px-4 ${currentPage === 1 ? 'text-gray-400' : 'cursor-pointer'}`}
                                onClick={handlePreviousPage}
                            >
                                <ArrowLeft01Icon />
                            </li>
                            {Array.from({ length: totalPages }).map((_, index) => (
                                <li
                                    key={index}
                                    className={`p-1 px-4 ${currentPage === index + 1 ? 'text-white bg-secondary' : 'bg-gray-300 cursor-pointer'}`}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </li>
                            ))}
                            <li
                                className={`p-1 px-4 ${currentPage === totalPages ? 'text-gray-400' : 'cursor-pointer'}`}
                                onClick={handleNextPage}
                            >
                                <ArrowRight01Icon />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='w-[27%] max-md:hidden flex flex-col gap-8'>
                    <div className='bg-primary text-white flex flex-col gap-4 px-8 py-[1.5rem]'>
                        <h3 className='text-[1.3rem] font-medium'>{t('your_choices')}</h3>
                        <span className='text-[.85rem]'>{t('publications')}</span>
                        <button
                            className='border-[1px] border-white py-1 px-4 w-full'
                            onClick={() => {
                                setSearchQuery('');
                                setSelectedDate('');
                                setCurrentPage(1);
                            }}
                        >
                            {t('reset_choices')}
                        </button>
                    </div>
                    <Publicite />
                    <Infolettre />
                </div>
            </div>
        </Annimated>
    );
}
