import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Banner from "../../components/Banner";

import imag from "../../assets/apropos/240619_20H48-49_8508-HRres_10po@300dpi-sRgb.jpg";
import imag2 from "../../assets/programmes/remca.png";
import imag3 from "../../assets/1x/sicaf.jpg";
import imag7 from "../../assets/apropos/imag7.webp";
import bois from "../../assets/1x/bois1.jpg";
import redeo from "../../assets/activite/redeo.jpg";
import ref from "../../assets/1x/ref.jpg";
import celeb from '../../assets/programmes/affiche.jpeg'
import Annimated from "../../components/Annimated";
import Publicite from "../../components/Publicite";
// import { Link } from 'react-router-dom';
// import Select from 'react-select';
// <Select
//     className="m-lg-2 yo"
//     options={options}
//     placeholder="Type d'entreprise"
//     onChange={(e) => setJanv(e.value)}
// // isDisabled
// />

export default function Evenements() {
  const { t } = useTranslation();

  const data = [
    {
      id:6,
      imag: celeb,
      date: '19 - 20 Fev 2025',
      titre: t('evennt.evennt6.titre'),
      descrip: t('evennt.evennt6.descrip')
    },
    {
      id: 2,
      imag: imag2,
      titre: t('evennt.evennt2.titre'),
      descrip:t('evennt.evennt2.descrip'),
      date: "Avril 2025"
    },
    {
      id: 1,
      imag: bois,
      titre:t('evennt.evennt1.titre'),
      descrip:t('evennt.evennt1.descrip'),
      date: "29 Avril - 01 Mai 2025"
    },
    {
      id: 4,
      imag: imag7,
      titre:t('evennt.evennt4.titre'),
      descrip:t('evennt.evennt4.descrip'),
      date: "Mai 2025"
    },
    {
      id: 3,
      imag: imag3,
      titre:t('evennt.evennt3.titre'),
      descrip:t('evennt.evennt3.descrip'),
      date: "14 - 16 Novembre 2025"
    },
  ];

  const datas = [
    {
      imag: redeo,
      titre: t('evennt.evennt5.titre'),
      type:'interne',
      descrip:t('evennt.evennt5.descrip'),
      // date: "13 - 22 Dec 2024"
    },
    {
      imag: ref,
      titre: t('evennt.evenp1.titre'),
      type:'externe',
      descrip:t('evennt.evenp1.descrip'),
      link: "https://www.cpq.qc.ca/laref23/"
    },
    {
      imag: imag,
      titre: t('evennt.evenp2.titre'),
      type:'externe',
      descrip:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has",
      link: "#"
    },
  ];

  return (
    <Annimated>
      {/* <main> */}

      <Banner title={t("events")} />
      <div className="px-[5%] flex max-md:flex-col gap-10 max-md:gap-[4.5rem] w-full py-14 max-md:py-12">
        <div className="flex flex-col w-[66%] max-md:w-full gap-1">
          <p className="text-[1.8rem] text-primary font-semibold max-md:text-[1.3rem] pb-2 border-b-[1px] border-primary">
            {t('upcoming_event')}
          </p>
          <div className="grid grid-cols-1 gap-3 mt-4">
            {data?.map((x, index) => (
              <a
                href={`/a/Evenements/${x.titre}`}
                key={index}
                className="relative flex flex-row-reverse items-center pb-4 max-md:flex-col gap-2 border-b-[1px] border-primary last-of-type:border-none overflow-hidden transition-all duration-500 rounded-sm shadow-sm cursor-pointer "
              >
                <div className="relative w-[35%] max-md:w-full h-full max-md:h-[180px]">
                  <img
                    className="absolute object-contain w-full h-full"
                    src={x.imag}
                    alt="#"
                  />
                </div>
                <div className="flex flex-col gap-3 w-[65%] max-md:w-full max-md:px-0 max-md:text-center">
                  <h1 className="text-[1.5rem] max-md:text-[1.2rem] hover:text-secondary transition-all duration-300 font-semibold text-primary">
                    {x.titre}
                  </h1>
                  <span
                    className="text-[1.1rem] max-md:text-[.95rem] font-light"
                    dangerouslySetInnerHTML={{
                      __html: `${x.descrip.slice(0, 250)}...`
                    }}
                  ></span>

                  <span className="italic text-green-600 text-[.8rem]">
                    {x.date}
                  </span>
                </div>
                {/* <div className='absolute '>
                  <span className='text-gray-400 text-[.8rem]'>16, Janv 2024</span>
                </div> */}
              </a>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-8 w-[34%] max-md:w-full">
          <div className="flex flex-col">
            <p className="text-[1.8rem] text-primary font-semibold max-md:text-[1.3rem]  pb-2 border-b-[1px] border-primary">
              {t('event_pass')}
            </p>
            <div className="grid grid-cols-1 gap-6 mt-4 gap-12max-lg:grid-cols-2 max-md:grid-cols-1">
              {datas?.map((x, index) => (
                <a
                  href={x.type==='interne'?'/a/Evenements/'+x.titre:x.link}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                  className="relative flex w-full gap-1 overflow-hidden transition-all duration-500 rounded-sm shadow cursor-pointer "
                >
                  <div className="w-[40%] relative">
                    <img
                      className="absolute object-fill w-full h-full"
                      src={x.imag}
                      alt="#"
                    />
                  </div>
                  <div className="w-[60%] py-3 px-3 max-md:px-2 flex flex-col gap-1">
                    <h1 className="text-[1rem] hover:text-secondary font-semibold text-primary">
                      {x.titre.slice(0, 35)}...
                    </h1>
                    <span className="text-[.8rem] font-light">
                      {x.descrip.slice(0, 106)}...
                    </span>
                  </div>
                  {/* <div className='bottom-0 left-0 w-full px-8 py-2 border-t-[1px] absolute'>
                  <span className='text-gray-400 text-[.8rem]'>16, Janv 2024</span>
                </div> */}
                </a>
              ))}
            </div>
          </div>
          <Publicite h={400}/>
        </div>
      </div>
    </Annimated>
  );
}
