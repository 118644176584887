import React from 'react'
import { useTranslation } from 'react-i18next'

export default function BureauExpertise() {
  const {t}=useTranslation()
  return (
    <div>
      {t('about.bureau_expert')}
    </div>
  )
}
